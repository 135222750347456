<template>
    <b-form-group
        :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
        class="mb-0"
        label-class="pt-0"
        label-cols="6"
        label-size=""
    >
        <div class="d-flex  justify-content-end align-items-center">
            <!-- accessibility -->
            <b-form-checkbox
                id="private"
                v-if="privateVisible && record.accessibility !== undefined"
                v-model="record.accessibility.private"
                name="private"
                switch
            >
                {{$t('form.accessibility.private')}}
            </b-form-checkbox>
            <!-- active -->
            <b-form-checkbox
                id="active"
                v-if="record.active !== undefined"
                class="ml-2"
                v-model="record.active"
                name="active"
                switch
            >
                {{activeText}}
            </b-form-checkbox>
        </div>
    </b-form-group>
</template>

<script>
export default {
    name: "FormIdActive",
    components: {},
    props: {
        record: {
            type: Object
        },
        privateVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    mounted() {
        if (this.privateVisible && (this.record.accessibility === undefined || this.record.private === undefined)) {
            this.$set(this.record, 'accessibility', {private: false});
        }
    },
    methods: {},
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
