<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        scrollable
        size="xl"
        @ok="formSave"
    >
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab basic -->
                        <b-tab :active="record.id > 0">
                            <template #title>
                                <b-icon v-if="tabBasicValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <b-card-text>
                                <!-- meta -->
                                <b-row>
                                    <!-- meta label -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$tc('form.meta.data', 0)"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- meta popup -->
                                <b-row>
                                    <!-- column a -->
                                    <b-col cols="6">
                                        <!-- meta name -->
                                        <b-form-group
                                            :label="$t('form.name') + '*'"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            description=""
                                            label-class="mb-0"
                                            label-for="meta_name"
                                        >
                                            <b-form-input
                                                id="meta_name"
                                                v-model="record.meta.name"
                                                :state="nameValidity"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                type="text"
                                            ></b-form-input>
                                            <b-form-invalid-feedback :state="nameValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <!-- basic data -->
                                        <b-form-group
                                            class="mb-2"
                                            label=""
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                    <!-- column b -->
                                    <!--                                    <b-col class="p-0 pr-0" cols="6">-->

                                    <!--                                    </b-col>-->
                                </b-row>

                                <!-- content -->
                                <b-row>
                                    <!-- label content -->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('form.content')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- content popup -->
                                <b-row>
                                    <!-- column a -->
                                    <b-col cols="6">
                                        <!-- experience_category -->
                                        <b-form-group
                                            class="mb-2 mr-sm-02 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$tc('adventure.experience_category.main', 0) + '*'"
                                            label-for="exCategories_id"
                                            description=""
                                            :state="exCategoryIdValidity"
                                        >
                                            <b-form-select
                                                id="exCategories_id"
                                                v-model="record.experience_category_id"
                                                :options="getExCategories"
                                                required
                                                :state="exCategoryIdValidity"
                                            >
                                            </b-form-select>
                                            <b-form-invalid-feedback :state="exCategoryIdValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <!-- column b -->
                                    <b-col cols="6">
                                        <!-- order -->
                                        <b-form-group
                                            id="group-order"
                                            class="mb-2 mr-sm-02 mb-sm-0"
                                            label-class="mb-0"
                                            :label="$t('form.order')"
                                            label-for="order"
                                            description=""
                                        >
                                            <b-form-input
                                                id="order"
                                                type="number"
                                                v-model="record.order"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                            <!-- basic locale -->
                            <form-ex-type-locale
                                :title="$t('form.basic.data')"
                                :data_locale="data.locale"
                                :record="record.locale"
                            ></form-ex-type-locale>
                        </b-tab>
                        <!-- description -->
                        <b-tab :title="$t('form.description')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('form.description')"
                                            :record="record.locale"
                                            color_class="editor-yellow"
                                            json_key="description"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- notes -->
                        <b-tab :title="$t('form.tab.notes')">
                            <b-card-text>
                                <b-form-group
                                    label=""
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('form.notes', 2)"
                                            :record="record.locale"
                                            json_key="notes"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import FormExTypeLocale from "@/components/adventure/type/FormExTypeLocale";
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";

export default {
    name: "ModalFormExType",
    components: {TabLocaleEditor, FormExTypeLocale, FormIdActive},
    props: {
        localeKey: null,
        uri: null,
        form: {},
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            recordDefault: {
                id: 0,
                experience_category_id: null,
                creator: null,
                active: false,
                order: 0,
                meta: {
                    name: null,
                },
                locale: {},
            },
            record: null,
            // <-- validity -->
            validityDefault: {
                check: false,
            },
            tabValidity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: {
                message: null
            },
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            this.recordId = recordId;
            this.form.id = recordId;
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- modal -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.form.id > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- form -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            this.tabValidity.check = true;
            let validity = true;
            // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.tabValidity).forEach(key => {
                    if (key !== 'check' && !this.tabValidity[key]) {
                        validity = false;
                    }
                });
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData();
                        return;
                    }
                    this.baseInsertFormData();
                }
            });
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            // this.$nextTick(() => {
            this.$set(this, 'record', this.__clone(this.recordDefault));
            let id = 0;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignDataActive`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.$bvModal.show(this.$options.name);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.form.id}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    let recordDefault = this.__clone(this.recordDefault);

                    let record = response.data.record;
                    this.__mergeObjectDeep(recordDefault, record);
                    this.$set(this, 'record', record);
                    // <--  -->
                    this.$bvModal.show(this.$options.name);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    console.log("error:", error.data);
                });
        },
        // <-- base -->
        baseInsertFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- data -->
            let data = {
                'record': this.record,
            };
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.post(this.uri.main, data, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
        baseUpdateFormData() {
            this.showOverlay(true);
            // <--  -->
            let record = this.record;
            // <-- clear errors -->
            this.resetErrors();
            // <-- data -->
            let data = {
                'record': record
            };
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.put(this.uri.main + `/${this.record.id}`, data, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.form.id <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            },
        },
        // <-- content -->
        getExCategories() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.exCategories)) {
                return array;
            }
            // <--  -->
            let items = this.data.exCategories;
            let text, purposeTypeName = null;
            items.forEach(item => {
                text = `(${item.meta.name})`;
                purposeTypeName = `${item.purpose_type.meta.name}`;
                // <-- locale -->
                if (!this.__isNull(item.locale) && !this.__isNull(item.locale.name)) {
                    text = item.locale.name;
                }
                if (!this.__isNull(item.purpose_type.locale) && !this.__isNull(item.purpose_type.locale.name)) {
                     purposeTypeName = item.purpose_type.locale.name;
                }
                // <-- push -->
                array.push({
                    text: `${text} (${purposeTypeName})`,
                    value: item.id
                });
            });
            // <-- sort -->
            array = array.sort(function (a, b) {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <-- add placeholder on top -->
            array.unshift({
                text: this.$t('form.choose_placeholder'),
                value: null
            });
            // <--  -->
            return array;
        },
        // <-- alerts -->
        alertVisible() {
            if (this.record === null) {
                return false;
            }
            // <--  -->
            return this.record.tax_rate_id === null;
        },
        getErrorsMessage() {
            let message = '';
            if (this.record !== null && this.record.tax_rate_id === null) {
                message += this.$t('finance.tax_rate_not_selected');
            }
            // <--  -->
            return message;
        },
        // <-- validity: tab basic -->
        nameValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.meta.name);
            return validity ? null : false;
        },
        exCategoryIdValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.experience_category_id > 0;
            return validity ? null : false;
        },
        tabBasicValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.nameValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.exCategoryIdValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabBasic', tabValidity);
            return tabValidity
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
